























































import { Download } from '@/types/state';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    downloads(): Download[] {
      return this.$store.getters['download/list'] || [];
    },
    activeDownloads(): Download[] {
      return this.downloads.filter((d) => !d.cancelled && !d.done && !d.error);
    },
    previewMax(): number {
      return this.activeDownloads.reduce((sum, upload) => upload.total + sum, 0.00001);
    },
    previewValue(): number {
      return this.activeDownloads.reduce((sum, upload) => upload.loaded + sum, 0.00001);
    },
  },
});
